import * as React from 'react';
import Layout from '../components/layout';

const FeaturedPage = () => (
  <Layout>
    <h2>Featured</h2>
  </Layout>
);

export default FeaturedPage;
